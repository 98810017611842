import React from 'react'
import Form from '../../components/Form'
import './styles.css'

export default function index() {
  return (
    <div className="newgerador">
        <h1>Gerador de Senhas e IPs</h1>
        <div className="newgerador__box">
            <Form />
        </div>
    </div>
  )
}
