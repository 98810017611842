import React, { useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
import './styles.css';

export default function InputPass() {

  const [senha, setSenha] = useState('Clique para gerar')
  const [aviso, setAviso] = useState('')
  const [ip, setIp] = useState('');

  let apiKey = 'bdc_5a6e5d3af65749458068303500957546';
  fetch('https://api.bigdatacloud.net/data/client-ip', {
      method: "GET",
      withCredentials: true,
      headers: {
        "X-Auth-Token": apiKey,
        "Content-Type": "application/json"
      }    
  })
  .then(
      resposta => {
          return resposta.json()
      }
  )
  .then(json => {
      setIp(json.ipString)
  })    

  function copiedIpAdvisor() {
    setAviso('IP copiado para a área de transferência!')
    setTimeout(() => {
      setAviso('')
    }, 5000);
  }

  function generatePassword() {
    setAviso('')
    var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJLMNOPQRSTUVWXYZ!@#$%^&*()+?><:";
    var passwordLength = 16;
    var password = "";
    for (var i = 0; i < passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
    }

    
    setSenha(password)
  }

  function passwordCopied() {
    setAviso('Senha copiada para a área de transferência!')
    setTimeout(() => {
      setAviso('')
    }, 5000);
  }

  return (
    <>
      <div className="inputIp__box">
          <p>Seu IP:</p>
          <CopyToClipboard text={ip}>
            <input className="inputIp" name="ip" value={ip} placeholder="IP" onClick={copiedIpAdvisor}></input>
          </CopyToClipboard>
      </div>    
      <div className="inputPass__box">
        <p>Sua sugestão da senha:</p>
        <CopyToClipboard text={senha}>
          <input className="inputPass" value={senha} name="passwd" placeholder="Senha" onClick={passwordCopied}></input>
        </CopyToClipboard>
      </div>
      <div className="button__box">
        <button className="buttonGenerate" name="button" onClick={generatePassword}>Gerar nova senha</button>
      </div>
      <p className="aviso">{aviso}</p>
    </>
  )
}
